import { create } from 'zustand';

interface OsState{
    os: string,
    setOs: (os:string) => void
}

export const useOsStore = create<OsState>()((set) => ({
    os: '',
    setOs: (os) => set({os}),
  }))