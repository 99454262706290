// import React, { useState, useEffect, useRef } from 'react';
// import styles from './randomIconSpread.module.scss';

// interface Position {
//   x: number;
//   y: number;
//   width: number;
//   height: number;
// }

// interface RandomIconSpreadProps {
//   svgIcons: React.ReactElement[];
//   iconSizeMultiplier?: number;
// }

// const RandomIconSpread: React.FC<RandomIconSpreadProps> = ({ svgIcons, iconSizeMultiplier = 1 }) => {
//   const [iconPositions, setIconPositions] = useState<Position[]>([]);
//   const containerRef = useRef<HTMLDivElement>(null);
//   const [touchPosition, setTouchPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
//   const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

//   useEffect(() => {
//     const calculatePositions = () => {
//       if (!containerRef.current) return;
//       const containerWidth = containerRef.current.clientWidth;
//       const containerHeight = containerRef.current.clientHeight;

//       const positions: Position[] = [];
//       const iconCount = svgIcons.length;
//       const iconSize = Math.min(containerWidth, containerHeight) / (Math.sqrt(iconCount) + 1) * iconSizeMultiplier;

//       const generateRandomPosition = (): Position => {
//         let position: Position;
//         let overlap: boolean;

//         do {
//           position = {
//             x: Math.random() * (containerWidth - iconSize),
//             y: Math.random() * (containerHeight - iconSize),
//             width: iconSize,
//             height: iconSize,
//           };

//           overlap = positions.some((existingPosition) => {
//             return (
//               position.x < existingPosition.x + existingPosition.width &&
//               position.x + position.width > existingPosition.x &&
//               position.y < existingPosition.y + existingPosition.height &&
//               position.y + position.height > existingPosition.y
//             );
//           });
//         } while (overlap);

//         return position;
//       };

//       for (let i = 0; i < iconCount; i++) {
//         positions.push(generateRandomPosition());
//       }
//       setIconPositions(positions);
//     };

//     calculatePositions();
//     window.addEventListener('resize', calculatePositions);

//     return () => {
//       window.removeEventListener('resize', calculatePositions);
//     };
//   }, [svgIcons, iconSizeMultiplier]);

//   const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
//     e.preventDefault(); // Prevent default touch behavior
//     if (e.touches.length > 0) {
//       setTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
//     }
//   };

//   const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
//     setMousePosition({ x: e.clientX, y: e.clientY });
//   };

//   const isWithinRange = (iconPosition: Position) => {
//     if (!iconPosition) return false;
//     const centerX = iconPosition.x + iconPosition.width / 2;
//     const centerY = iconPosition.y + iconPosition.height / 2;
//     let distance;
//     if (window.innerWidth < 1200) {
//       distance = Math.sqrt(
//         Math.pow(touchPosition.x - centerX, 2) + Math.pow(touchPosition.y - centerY, 2)
//       );
//     } else {
//       distance = Math.sqrt(
//         Math.pow(mousePosition.x - centerX, 2) + Math.pow(mousePosition.y - centerY, 2)
//       );
//     }

//     const range = Math.min(window.innerWidth, window.innerHeight) * 0.2;
//     return distance <= range;
//   };

//   return (
//     <div
//       ref={containerRef}
//       className={styles.icons}
//       onTouchMove={handleTouchMove}
//       onMouseMove={handleMouseMove}
//     >
//       <div className={styles.iconWrapper}>
//         {svgIcons.map((icon, index) => (
//           <div
//             key={index}
//             style={{
//               position: 'absolute',
//               left: `${iconPositions[index]?.x}px`,
//               top: `${iconPositions[index]?.y}px`,
//               width: `${iconPositions[index]?.width}px`,
//               height: `${iconPositions[index]?.height}px`,
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//             className={`${styles.iconContainer} ${isWithinRange(iconPositions[index]) ? styles.active : ''}`}
//           >
//             {icon}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default RandomIconSpread;


import React, { useState, useEffect, useRef } from 'react';
import styles from './randomIconSpread.module.scss';

interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface RandomIconSpreadProps {
  svgIcons: React.ReactElement[];
  iconSizeMultiplier?: number;
}

const RandomIconSpread: React.FC<RandomIconSpreadProps> = ({ svgIcons, iconSizeMultiplier = 1 }) => {
  const [iconPositions, setIconPositions] = useState<Position[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const calculatePositions = () => {
      if (!containerRef.current) return;
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      const positions: Position[] = [];
      const iconCount = svgIcons.length;
      const iconSize = Math.min(containerWidth, containerHeight) / (Math.sqrt(iconCount) + 1) * iconSizeMultiplier;

      // if (isMobile) {
      //   // Top-to-bottom pattern for mobile
      //   const iconsPerRow = Math.ceil(Math.sqrt(iconCount));
      //   const iconSpacingX = containerWidth / iconsPerRow;
      //   const iconSpacingY = containerHeight / Math.ceil(iconCount / iconsPerRow);

      //   for (let i = 0; i < iconCount; i++) {
      //     const row = Math.floor(i / iconsPerRow);
      //     const col = i % iconsPerRow;
      //     positions.push({
      //       x: col * iconSpacingX + (iconSpacingX - iconSize) / 2,
      //       y: row * iconSpacingY + (iconSpacingY - iconSize) / 2,
      //       width: iconSize,
      //       height: iconSize,
      //     });
      //   }
      // } else {
        // Random pattern for desktop and mobile when isMobile is false.
        const generateRandomPosition = (): Position => {
          let position: Position;
          let overlap: boolean;

          do {
            position = {
              x: Math.random() * (containerWidth - iconSize),
              y: Math.random() * (containerHeight - iconSize),
              width: iconSize,
              height: iconSize,
            };

            overlap = positions.some((existingPosition) => {
              return (
                position.x < existingPosition.x + existingPosition.width &&
                position.x + position.width > existingPosition.x &&
                position.y < existingPosition.y + existingPosition.height &&
                position.y + position.height > existingPosition.y
              );
            });
          } while (overlap);

          return position;
        };

        for (let i = 0; i < iconCount; i++) {
          positions.push(generateRandomPosition());
        }
      // }

      setIconPositions(positions);
    };

    calculatePositions();
    window.addEventListener('resize', calculatePositions);

    return () => {
      window.removeEventListener('resize', calculatePositions);
    };
  }, [svgIcons, iconSizeMultiplier, isMobile]);

  useEffect(() => {
    let progress = 0;
    const speed = 0.005;

    const moveMousePosition = () => {
      if (!containerRef.current) return;
      const containerHeight = window.innerHeight;

      const y = progress * containerHeight;
      const x = window.innerWidth / 2;

      setMousePosition({ x, y });

      progress += speed;

      if (progress > 1) {
        progress = 0;
      }
    };

    let interval: any;

    if (isMobile) {
      moveMousePosition();
      interval = setInterval(moveMousePosition, 100);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isMobile]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMobile) {
      setMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  const isWithinRange = (iconPosition: Position) => {
    if (!iconPosition) return false;
    const centerX = iconPosition.x + iconPosition.width / 2;
    const centerY = iconPosition.y + iconPosition.height / 2;

    if (isMobile) {
      const rangeWidth = window.innerWidth;
      const rangeHeight = window.innerHeight * 0.2;

      const isWithinX = centerX >= mousePosition.x - rangeWidth / 2 && centerX <= mousePosition.x + rangeWidth / 2;
      const isWithinY = centerY >= mousePosition.y - rangeHeight / 2 && centerY <= mousePosition.y + rangeHeight / 2;

      return isWithinX && isWithinY;
    } else {
      const distance = Math.sqrt(
        Math.pow(mousePosition.x - centerX, 2) + Math.pow(mousePosition.y - centerY, 2)
      );

      const distanceToLeft = mousePosition.x;
      const distanceToRight = window.innerWidth - mousePosition.x;
      const distanceToTop = mousePosition.y;
      const distanceToBottom = window.innerHeight - mousePosition.y;

      const minDistanceToEdge = Math.min(
        distanceToLeft,
        distanceToRight,
        distanceToTop,
        distanceToBottom
      );

      let range = Math.max(minDistanceToEdge, Math.min(window.innerWidth, window.innerHeight) * 0.2);

      return distance <= range;
    }
  };

  return (
    <div
      ref={containerRef}
      className={styles.icons}
      onMouseMove={handleMouseMove}
    >
      <div className={styles.iconWrapper}>
        {svgIcons.map((icon, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: `${iconPositions[index]?.x}px`,
              top: `${iconPositions[index]?.y}px`,
              width: `${iconPositions[index]?.width}px`,
              height: `${iconPositions[index]?.height}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className={`${styles.iconContainer} ${isWithinRange(iconPositions[index]) ? styles.active : ''}`}
          >
            {icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RandomIconSpread;