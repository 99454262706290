import React from 'react';
import { ReactComponent as Crypto_icon1 } from '../../assets/svg/crypto_symbols/Crypto_icon1.svg';
import { ReactComponent as Crypto_icon2 } from '../../assets/svg/crypto_symbols/Crypto_icon2.svg';
import { ReactComponent as Crypto_icon3 } from '../../assets/svg/crypto_symbols/Crypto_icon3.svg';
import { ReactComponent as Crypto_icon4 } from '../../assets/svg/crypto_symbols/Crypto_icon4.svg';
import { ReactComponent as Crypto_icon5 } from '../../assets/svg/crypto_symbols/Crypto_icon5.svg';
import { ReactComponent as Crypto_icon6 } from '../../assets/svg/crypto_symbols/Crypto_icon6.svg';
import { ReactComponent as Crypto_icon7 } from '../../assets/svg/crypto_symbols/Crypto_icon7.svg';
import { ReactComponent as Crypto_icon8 } from '../../assets/svg/crypto_symbols/Crypto_icon8.svg';
import { ReactComponent as Crypto_icon9 } from '../../assets/svg/crypto_symbols/Crypto_icon9.svg';
import { ReactComponent as Crypto_icon10 } from '../../assets/svg/crypto_symbols/Crypto_icon10.svg';
import { ReactComponent as Crypto_icon11 } from '../../assets/svg/crypto_symbols/Crypto_icon11.svg';
import { ReactComponent as Crypto_icon12 } from '../../assets/svg/crypto_symbols/Crypto_icon12.svg';
import { ReactComponent as Crypto_icon13 } from '../../assets/svg/crypto_symbols/Crypto_icon13.svg';
import { ReactComponent as Crypto_icon14 } from '../../assets/svg/crypto_symbols/Crypto_icon14.svg';
import { ReactComponent as Crypto_icon15 } from '../../assets/svg/crypto_symbols/Crypto_icon15.svg';
import { ReactComponent as Crypto_icon16 } from '../../assets/svg/crypto_symbols/Crypto_icon16.svg';
import { ReactComponent as Crypto_icon17 } from '../../assets/svg/crypto_symbols/Crypto_icon17.svg';
import { ReactComponent as Crypto_icon18 } from '../../assets/svg/crypto_symbols/Crypto_icon18.svg';
import { ReactComponent as Crypto_icon19 } from '../../assets/svg/crypto_symbols/Crypto_icon19.svg';
import { ReactComponent as Crypto_icon20 } from '../../assets/svg/crypto_symbols/Crypto_icon20.svg';
import { ReactComponent as Crypto_icon21 } from '../../assets/svg/crypto_symbols/Crypto_icon21.svg';
import { ReactComponent as Crypto_icon22 } from '../../assets/svg/crypto_symbols/Crypto_icon22.svg';
import { ReactComponent as Crypto_icon23 } from '../../assets/svg/crypto_symbols/Crypto_icon23.svg';
import { ReactComponent as Crypto_icon24 } from '../../assets/svg/crypto_symbols/Crypto_icon24.svg';
import { ReactComponent as Crypto_icon25 } from '../../assets/svg/crypto_symbols/Crypto_icon25.svg';
import { ReactComponent as Crypto_icon26 } from '../../assets/svg/crypto_symbols/Crypto_icon26.svg';
import { ReactComponent as Crypto_icon27 } from '../../assets/svg/crypto_symbols/Crypto_icon27.svg';
import { ReactComponent as Crypto_icon28 } from '../../assets/svg/crypto_symbols/Crypto_icon28.svg';
import { ReactComponent as Crypto_icon29 } from '../../assets/svg/crypto_symbols/Crypto_icon29.svg';
import { ReactComponent as Crypto_icon30 } from '../../assets/svg/crypto_symbols/Crypto_icon30.svg';
import { ReactComponent as Crypto_icon31 } from '../../assets/svg/crypto_symbols/Crypto_icon31.svg';
import { ReactComponent as Crypto_icon32 } from '../../assets/svg/crypto_symbols/Crypto_icon32.svg';
import { ReactComponent as Crypto_icon33 } from '../../assets/svg/crypto_symbols/Crypto_icon33.svg';
import { ReactComponent as Crypto_icon34 } from '../../assets/svg/crypto_symbols/Crypto_icon34.svg';
import { ReactComponent as Crypto_icon35 } from '../../assets/svg/crypto_symbols/Crypto_icon35.svg';
import { ReactComponent as Crypto_icon36 } from '../../assets/svg/crypto_symbols/Crypto_icon36.svg';
import { ReactComponent as Crypto_icon37 } from '../../assets/svg/crypto_symbols/Crypto_icon37.svg';
import { ReactComponent as Crypto_icon38 } from '../../assets/svg/crypto_symbols/Crypto_icon38.svg';
import { ReactComponent as Crypto_icon39 } from '../../assets/svg/crypto_symbols/Crypto_icon39.svg';
import { ReactComponent as Crypto_icon40 } from '../../assets/svg/crypto_symbols/Crypto_icon40.svg';
import { ReactComponent as Crypto_icon41 } from '../../assets/svg/crypto_symbols/Crypto_icon41.svg';
import { ReactComponent as Crypto_icon42 } from '../../assets/svg/crypto_symbols/Crypto_icon42.svg';
import { ReactComponent as Crypto_icon43 } from '../../assets/svg/crypto_symbols/Crypto_icon43.svg';
import { ReactComponent as Crypto_icon44 } from '../../assets/svg/crypto_symbols/Crypto_icon44.svg';
import { ReactComponent as Crypto_icon45 } from '../../assets/svg/crypto_symbols/Crypto_icon45.svg';
import { ReactComponent as Crypto_icon46 } from '../../assets/svg/crypto_symbols/Crypto_icon46.svg';
import { ReactComponent as Crypto_icon47 } from '../../assets/svg/crypto_symbols/Crypto_icon47.svg';
import { ReactComponent as Crypto_icon48 } from '../../assets/svg/crypto_symbols/Crypto_icon48.svg';
import { ReactComponent as Crypto_icon49 } from '../../assets/svg/crypto_symbols/Crypto_icon49.svg';
import { ReactComponent as Crypto_icon50 } from '../../assets/svg/crypto_symbols/Crypto_icon50.svg';
import { ReactComponent as Crypto_icon51 } from '../../assets/svg/crypto_symbols/Crypto_icon51.svg';
import { ReactComponent as Crypto_icon52 } from '../../assets/svg/crypto_symbols/Crypto_icon52.svg';
import { ReactComponent as Crypto_icon53 } from '../../assets/svg/crypto_symbols/Crypto_icon53.svg';
import { ReactComponent as Crypto_icon54 } from '../../assets/svg/crypto_symbols/Crypto_icon54.svg';
import { ReactComponent as Crypto_icon55 } from '../../assets/svg/crypto_symbols/Crypto_icon55.svg';
import { ReactComponent as Crypto_icon56 } from '../../assets/svg/crypto_symbols/Crypto_icon56.svg';
import { ReactComponent as Crypto_icon57 } from '../../assets/svg/crypto_symbols/Crypto_icon57.svg';
import { ReactComponent as Crypto_icon58 } from '../../assets/svg/crypto_symbols/Crypto_icon58.svg';
import { ReactComponent as Crypto_icon59 } from '../../assets/svg/crypto_symbols/Crypto_icon59.svg';
import { ReactComponent as Crypto_icon60 } from '../../assets/svg/crypto_symbols/Crypto_icon60.svg';
import { ReactComponent as Crypto_icon61 } from '../../assets/svg/crypto_symbols/Crypto_icon61.svg';
import { ReactComponent as Crypto_icon62 } from '../../assets/svg/crypto_symbols/Crypto_icon62.svg';
import { ReactComponent as Crypto_icon63 } from '../../assets/svg/crypto_symbols/Crypto_icon63.svg';
import { ReactComponent as Crypto_icon64 } from '../../assets/svg/crypto_symbols/Crypto_icon64.svg';
import { ReactComponent as Crypto_icon65 } from '../../assets/svg/crypto_symbols/Crypto_icon65.svg';
import { ReactComponent as Crypto_icon66 } from '../../assets/svg/crypto_symbols/Crypto_icon66.svg';
import { ReactComponent as Crypto_icon67 } from '../../assets/svg/crypto_symbols/Crypto_icon67.svg';
import { ReactComponent as Crypto_icon68 } from '../../assets/svg/crypto_symbols/Crypto_icon68.svg';
import { ReactComponent as Crypto_icon69 } from '../../assets/svg/crypto_symbols/Crypto_icon69.svg';
import { ReactComponent as Crypto_icon70 } from '../../assets/svg/crypto_symbols/Crypto_icon70.svg';
import { ReactComponent as Crypto_icon71 } from '../../assets/svg/crypto_symbols/Crypto_icon71.svg';
import { ReactComponent as Crypto_icon72 } from '../../assets/svg/crypto_symbols/Crypto_icon72.svg';
import { ReactComponent as Crypto_icon73 } from '../../assets/svg/crypto_symbols/Crypto_icon73.svg';
import { ReactComponent as Crypto_icon74 } from '../../assets/svg/crypto_symbols/Crypto_icon74.svg';
import { ReactComponent as Crypto_icon75 } from '../../assets/svg/crypto_symbols/Crypto_icon75.svg';
import { ReactComponent as Crypto_icon76 } from '../../assets/svg/crypto_symbols/Crypto_icon76.svg';
import { ReactComponent as Crypto_icon77 } from '../../assets/svg/crypto_symbols/Crypto_icon77.svg';
import { ReactComponent as Crypto_icon78 } from '../../assets/svg/crypto_symbols/Crypto_icon78.svg';
import { ReactComponent as Crypto_icon79 } from '../../assets/svg/crypto_symbols/Crypto_icon79.svg';
import { ReactComponent as Crypto_icon80 } from '../../assets/svg/crypto_symbols/Crypto_icon80.svg';
import { ReactComponent as Crypto_icon81 } from '../../assets/svg/crypto_symbols/Crypto_icon81.svg';
import { ReactComponent as Crypto_icon82 } from '../../assets/svg/crypto_symbols/Crypto_icon82.svg';
import { ReactComponent as Crypto_icon83 } from '../../assets/svg/crypto_symbols/Crypto_icon83.svg';
import { ReactComponent as Crypto_icon84 } from '../../assets/svg/crypto_symbols/Crypto_icon84.svg';
import { ReactComponent as Crypto_icon85 } from '../../assets/svg/crypto_symbols/Crypto_icon85.svg';
import { ReactComponent as Crypto_icon86 } from '../../assets/svg/crypto_symbols/Crypto_icon86.svg';
import { ReactComponent as Crypto_icon87 } from '../../assets/svg/crypto_symbols/Crypto_icon87.svg';
import { ReactComponent as Crypto_icon88 } from '../../assets/svg/crypto_symbols/Crypto_icon88.svg';
import { ReactComponent as Crypto_icon89 } from '../../assets/svg/crypto_symbols/Crypto_icon89.svg';
import { ReactComponent as Crypto_icon90 } from '../../assets/svg/crypto_symbols/Crypto_icon90.svg';
import { ReactComponent as Crypto_icon91 } from '../../assets/svg/crypto_symbols/Crypto_icon91.svg';
import { ReactComponent as Crypto_icon92 } from '../../assets/svg/crypto_symbols/Crypto_icon92.svg';
import { ReactComponent as Crypto_icon93 } from '../../assets/svg/crypto_symbols/Crypto_icon93.svg';
import { ReactComponent as Crypto_icon94 } from '../../assets/svg/crypto_symbols/Crypto_icon94.svg';
import { ReactComponent as Crypto_icon95 } from '../../assets/svg/crypto_symbols/Crypto_icon95.svg';
import { ReactComponent as Crypto_icon96 } from '../../assets/svg/crypto_symbols/Crypto_icon96.svg';
import { ReactComponent as Crypto_icon97 } from '../../assets/svg/crypto_symbols/Crypto_icon97.svg';
import { ReactComponent as Crypto_icon98 } from '../../assets/svg/crypto_symbols/Crypto_icon98.svg';
import { ReactComponent as Crypto_icon99 } from '../../assets/svg/crypto_symbols/Crypto_icon99.svg';
import { ReactComponent as Crypto_icon100 } from '../../assets/svg/crypto_symbols/Crypto_icon100.svg';
import { ReactComponent as Crypto_icon101 } from '../../assets/svg/crypto_symbols/Crypto_icon101.svg';
import { ReactComponent as Crypto_icon102 } from '../../assets/svg/crypto_symbols/Crypto_icon102.svg';
import { ReactComponent as Crypto_icon103 } from '../../assets/svg/crypto_symbols/Crypto_icon103.svg';
import { ReactComponent as Crypto_icon104 } from '../../assets/svg/crypto_symbols/Crypto_icon104.svg';
import { ReactComponent as Crypto_icon105 } from '../../assets/svg/crypto_symbols/Crypto_icon105.svg';
import { ReactComponent as Crypto_icon106 } from '../../assets/svg/crypto_symbols/Crypto_icon106.svg';
import { ReactComponent as Crypto_icon107 } from '../../assets/svg/crypto_symbols/Crypto_icon107.svg';
import { ReactComponent as Crypto_icon108 } from '../../assets/svg/crypto_symbols/Crypto_icon108.svg';
import { ReactComponent as Crypto_icon109 } from '../../assets/svg/crypto_symbols/Crypto_icon109.svg';
import { ReactComponent as Crypto_icon110 } from '../../assets/svg/crypto_symbols/Crypto_icon110.svg';
import { ReactComponent as Crypto_icon111 } from '../../assets/svg/crypto_symbols/Crypto_icon111.svg';
import { ReactComponent as Crypto_icon112 } from '../../assets/svg/crypto_symbols/Crypto_icon112.svg';
import { ReactComponent as Crypto_icon113 } from '../../assets/svg/crypto_symbols/Crypto_icon113.svg';
import { ReactComponent as Crypto_icon114 } from '../../assets/svg/crypto_symbols/Crypto_icon114.svg';
import { ReactComponent as Crypto_icon115 } from '../../assets/svg/crypto_symbols/Crypto_icon115.svg';
import { ReactComponent as Crypto_icon116 } from '../../assets/svg/crypto_symbols/Crypto_icon116.svg';
import { ReactComponent as Crypto_icon117 } from '../../assets/svg/crypto_symbols/Crypto_icon117.svg';
import { ReactComponent as Crypto_icon118 } from '../../assets/svg/crypto_symbols/Crypto_icon118.svg';
import { ReactComponent as Crypto_icon119 } from '../../assets/svg/crypto_symbols/Crypto_icon119.svg';
import { ReactComponent as Crypto_icon120 } from '../../assets/svg/crypto_symbols/Crypto_icon120.svg';
import { ReactComponent as Crypto_icon121 } from '../../assets/svg/crypto_symbols/Crypto_icon121.svg';
import { ReactComponent as Crypto_icon122 } from '../../assets/svg/crypto_symbols/Crypto_icon122.svg';
import { ReactComponent as Crypto_icon123 } from '../../assets/svg/crypto_symbols/Crypto_icon123.svg';
import { ReactComponent as Crypto_icon124 } from '../../assets/svg/crypto_symbols/Crypto_icon124.svg';
import { ReactComponent as Crypto_icon125 } from '../../assets/svg/crypto_symbols/Crypto_icon125.svg';
import { ReactComponent as Crypto_icon126 } from '../../assets/svg/crypto_symbols/Crypto_icon126.svg';
import { ReactComponent as Crypto_icon127 } from '../../assets/svg/crypto_symbols/Crypto_icon127.svg';
import { ReactComponent as Crypto_icon128 } from '../../assets/svg/crypto_symbols/Crypto_icon128.svg';
import { ReactComponent as Crypto_icon129 } from '../../assets/svg/crypto_symbols/Crypto_icon129.svg';
import { ReactComponent as Crypto_icon130 } from '../../assets/svg/crypto_symbols/Crypto_icon130.svg';
import { ReactComponent as Crypto_icon131 } from '../../assets/svg/crypto_symbols/Crypto_icon131.svg';
import { ReactComponent as Crypto_icon132 } from '../../assets/svg/crypto_symbols/Crypto_icon132.svg';
import { ReactComponent as Crypto_icon133 } from '../../assets/svg/crypto_symbols/Crypto_icon133.svg';
import { ReactComponent as Crypto_icon134 } from '../../assets/svg/crypto_symbols/Crypto_icon134.svg';
import { ReactComponent as Crypto_icon135 } from '../../assets/svg/crypto_symbols/Crypto_icon135.svg';
import { ReactComponent as Crypto_icon136 } from '../../assets/svg/crypto_symbols/Crypto_icon136.svg';
import { ReactComponent as Crypto_icon137 } from '../../assets/svg/crypto_symbols/Crypto_icon137.svg';
import { ReactComponent as Crypto_icon138 } from '../../assets/svg/crypto_symbols/Crypto_icon138.svg';
import { ReactComponent as Crypto_icon139 } from '../../assets/svg/crypto_symbols/Crypto_icon139.svg';
import { ReactComponent as Crypto_icon140 } from '../../assets/svg/crypto_symbols/Crypto_icon140.svg';
import { ReactComponent as Crypto_icon141 } from '../../assets/svg/crypto_symbols/Crypto_icon141.svg';
import { ReactComponent as Crypto_icon142 } from '../../assets/svg/crypto_symbols/Crypto_icon142.svg';
import { ReactComponent as Crypto_icon143 } from '../../assets/svg/crypto_symbols/Crypto_icon143.svg';
import { ReactComponent as Crypto_icon144 } from '../../assets/svg/crypto_symbols/Crypto_icon144.svg';
import { ReactComponent as Crypto_icon145 } from '../../assets/svg/crypto_symbols/Crypto_icon145.svg';
import { ReactComponent as Crypto_icon146 } from '../../assets/svg/crypto_symbols/Crypto_icon146.svg';
import { ReactComponent as Crypto_icon147 } from '../../assets/svg/crypto_symbols/Crypto_icon147.svg';
import { ReactComponent as Crypto_icon148 } from '../../assets/svg/crypto_symbols/Crypto_icon148.svg';
import { ReactComponent as Crypto_icon149 } from '../../assets/svg/crypto_symbols/Crypto_icon149.svg';
import { ReactComponent as Crypto_icon150 } from '../../assets/svg/crypto_symbols/Crypto_icon150.svg';
import { ReactComponent as Crypto_icon151 } from '../../assets/svg/crypto_symbols/Crypto_icon151.svg';
import { ReactComponent as Crypto_icon152 } from '../../assets/svg/crypto_symbols/Crypto_icon152.svg';
import { ReactComponent as Crypto_icon153 } from '../../assets/svg/crypto_symbols/Crypto_icon153.svg';
import { ReactComponent as Crypto_icon154 } from '../../assets/svg/crypto_symbols/Crypto_icon154.svg';
import { ReactComponent as Crypto_icon155 } from '../../assets/svg/crypto_symbols/Crypto_icon155.svg';
import { ReactComponent as Crypto_icon156 } from '../../assets/svg/crypto_symbols/Crypto_icon156.svg';
import { ReactComponent as Crypto_icon157 } from '../../assets/svg/crypto_symbols/Crypto_icon157.svg';
import { ReactComponent as Crypto_icon158 } from '../../assets/svg/crypto_symbols/Crypto_icon158.svg';
import { ReactComponent as Crypto_icon159 } from '../../assets/svg/crypto_symbols/Crypto_icon159.svg';
import { ReactComponent as Crypto_icon160 } from '../../assets/svg/crypto_symbols/Crypto_icon160.svg';
import { ReactComponent as Crypto_icon161 } from '../../assets/svg/crypto_symbols/Crypto_icon161.svg';
import { ReactComponent as Crypto_icon162 } from '../../assets/svg/crypto_symbols/Crypto_icon162.svg';
import { ReactComponent as Crypto_icon163 } from '../../assets/svg/crypto_symbols/Crypto_icon163.svg';
import { ReactComponent as Crypto_icon164 } from '../../assets/svg/crypto_symbols/Crypto_icon164.svg';
import { ReactComponent as Crypto_icon165 } from '../../assets/svg/crypto_symbols/Crypto_icon165.svg';
import { ReactComponent as Crypto_icon166 } from '../../assets/svg/crypto_symbols/Crypto_icon166.svg';
import { ReactComponent as Crypto_icon167 } from '../../assets/svg/crypto_symbols/Crypto_icon167.svg';
import { ReactComponent as Crypto_icon168 } from '../../assets/svg/crypto_symbols/Crypto_icon168.svg';
import { ReactComponent as Crypto_icon169 } from '../../assets/svg/crypto_symbols/Crypto_icon169.svg';
import { ReactComponent as Crypto_icon170 } from '../../assets/svg/crypto_symbols/Crypto_icon170.svg';
import { ReactComponent as Crypto_icon171 } from '../../assets/svg/crypto_symbols/Crypto_icon171.svg';
import { ReactComponent as Crypto_icon172 } from '../../assets/svg/crypto_symbols/Crypto_icon172.svg';
import { ReactComponent as Crypto_icon173 } from '../../assets/svg/crypto_symbols/Crypto_icon173.svg';
import { ReactComponent as Crypto_icon174 } from '../../assets/svg/crypto_symbols/Crypto_icon174.svg';
import { ReactComponent as Crypto_icon175 } from '../../assets/svg/crypto_symbols/Crypto_icon175.svg';
import { ReactComponent as Crypto_icon176 } from '../../assets/svg/crypto_symbols/Crypto_icon176.svg';
import { ReactComponent as Crypto_icon177 } from '../../assets/svg/crypto_symbols/Crypto_icon177.svg';
import { ReactComponent as Crypto_icon178 } from '../../assets/svg/crypto_symbols/Crypto_icon178.svg';
import { ReactComponent as Crypto_icon179 } from '../../assets/svg/crypto_symbols/Crypto_icon179.svg';
import { ReactComponent as Crypto_icon180 } from '../../assets/svg/crypto_symbols/Crypto_icon180.svg';
import { ReactComponent as Crypto_icon181 } from '../../assets/svg/crypto_symbols/Crypto_icon181.svg';
import { ReactComponent as Crypto_icon182 } from '../../assets/svg/crypto_symbols/Crypto_icon182.svg';
import { ReactComponent as Crypto_icon183 } from '../../assets/svg/crypto_symbols/Crypto_icon183.svg';
import { ReactComponent as Crypto_icon184 } from '../../assets/svg/crypto_symbols/Crypto_icon184.svg';
import { ReactComponent as Crypto_icon185 } from '../../assets/svg/crypto_symbols/Crypto_icon185.svg';
import { ReactComponent as Crypto_icon186 } from '../../assets/svg/crypto_symbols/Crypto_icon186.svg';
import { ReactComponent as Crypto_icon187 } from '../../assets/svg/crypto_symbols/Crypto_icon187.svg';
import { ReactComponent as Crypto_icon188 } from '../../assets/svg/crypto_symbols/Crypto_icon188.svg';
import { ReactComponent as Crypto_icon189 } from '../../assets/svg/crypto_symbols/Crypto_icon189.svg';
import { ReactComponent as Crypto_icon190 } from '../../assets/svg/crypto_symbols/Crypto_icon190.svg';
import { ReactComponent as Crypto_icon191 } from '../../assets/svg/crypto_symbols/Crypto_icon191.svg';
import { ReactComponent as Crypto_icon192 } from '../../assets/svg/crypto_symbols/Crypto_icon192.svg';
import { ReactComponent as Crypto_icon193 } from '../../assets/svg/crypto_symbols/Crypto_icon193.svg';
import { ReactComponent as Crypto_icon194 } from '../../assets/svg/crypto_symbols/Crypto_icon194.svg';
import { ReactComponent as Crypto_icon195 } from '../../assets/svg/crypto_symbols/Crypto_icon195.svg';
import { ReactComponent as Crypto_icon196 } from '../../assets/svg/crypto_symbols/Crypto_icon196.svg';
import { ReactComponent as Crypto_icon197 } from '../../assets/svg/crypto_symbols/Crypto_icon197.svg';
import { ReactComponent as Crypto_icon198 } from '../../assets/svg/crypto_symbols/Crypto_icon198.svg';
import { ReactComponent as Crypto_icon199 } from '../../assets/svg/crypto_symbols/Crypto_icon199.svg';
import { ReactComponent as Crypto_icon200 } from '../../assets/svg/crypto_symbols/Crypto_icon200.svg';
import { ReactComponent as Crypto_icon201 } from '../../assets/svg/crypto_symbols/Crypto_icon201.svg';
import { ReactComponent as Crypto_icon202 } from '../../assets/svg/crypto_symbols/Crypto_icon202.svg';
import { ReactComponent as Crypto_icon203 } from '../../assets/svg/crypto_symbols/Crypto_icon203.svg';
import { ReactComponent as Crypto_icon204 } from '../../assets/svg/crypto_symbols/Crypto_icon204.svg';
import { ReactComponent as Crypto_icon205 } from '../../assets/svg/crypto_symbols/Crypto_icon205.svg';
import { ReactComponent as Crypto_icon206 } from '../../assets/svg/crypto_symbols/Crypto_icon206.svg';
import { ReactComponent as Crypto_icon207 } from '../../assets/svg/crypto_symbols/Crypto_icon207.svg';
import { ReactComponent as Crypto_icon208 } from '../../assets/svg/crypto_symbols/Crypto_icon208.svg';
import { ReactComponent as Crypto_icon209 } from '../../assets/svg/crypto_symbols/Crypto_icon209.svg';
import { ReactComponent as Crypto_icon210 } from '../../assets/svg/crypto_symbols/Crypto_icon210.svg';
import { ReactComponent as Crypto_icon211 } from '../../assets/svg/crypto_symbols/Crypto_icon211.svg';
import { ReactComponent as Crypto_icon212 } from '../../assets/svg/crypto_symbols/Crypto_icon212.svg';
import { ReactComponent as Crypto_icon213 } from '../../assets/svg/crypto_symbols/Crypto_icon213.svg';
import { ReactComponent as Crypto_icon214 } from '../../assets/svg/crypto_symbols/Crypto_icon214.svg';
import { ReactComponent as Crypto_icon215 } from '../../assets/svg/crypto_symbols/Crypto_icon215.svg';

import { CryptoIcon } from '../types/cryptoIconsType';

export const CRYPTO_ICONS: CryptoIcon[] = [
  { icon: Crypto_icon1 },
  { icon: Crypto_icon2 },
  { icon: Crypto_icon3 },
  { icon: Crypto_icon4 },
  { icon: Crypto_icon5 },
  { icon: Crypto_icon6 },
  { icon: Crypto_icon7 },
  { icon: Crypto_icon8 },
  { icon: Crypto_icon9 },
  { icon: Crypto_icon10 },
  { icon: Crypto_icon11 },
  { icon: Crypto_icon12 },
  { icon: Crypto_icon13 },
  { icon: Crypto_icon14 },
  { icon: Crypto_icon15 },
  { icon: Crypto_icon16 },
  { icon: Crypto_icon17 },
  { icon: Crypto_icon18 },
  { icon: Crypto_icon19 },
  { icon: Crypto_icon20 },
  { icon: Crypto_icon21 },
  { icon: Crypto_icon22 },
  { icon: Crypto_icon23 },
  { icon: Crypto_icon24 },
  { icon: Crypto_icon25 },
  { icon: Crypto_icon26 },
  { icon: Crypto_icon27 },
  { icon: Crypto_icon28 },
  { icon: Crypto_icon29 },
  { icon: Crypto_icon30 },
  { icon: Crypto_icon31 },
  { icon: Crypto_icon32 },
  { icon: Crypto_icon33 },
  { icon: Crypto_icon34 },
  { icon: Crypto_icon35 },
  { icon: Crypto_icon36 },
  { icon: Crypto_icon37 },
  { icon: Crypto_icon38 },
  { icon: Crypto_icon39 },
  { icon: Crypto_icon40 },
  { icon: Crypto_icon41 },
  { icon: Crypto_icon42 },
  { icon: Crypto_icon43 },
  { icon: Crypto_icon44 },
  { icon: Crypto_icon45 },
  { icon: Crypto_icon46 },
  { icon: Crypto_icon47 },
  { icon: Crypto_icon48 },
  { icon: Crypto_icon49 },
  { icon: Crypto_icon50 },
  { icon: Crypto_icon51 },
  { icon: Crypto_icon52 },
  { icon: Crypto_icon53 },
  { icon: Crypto_icon54 },
  { icon: Crypto_icon55 },
  { icon: Crypto_icon56 },
  { icon: Crypto_icon57 },
  { icon: Crypto_icon58 },
  { icon: Crypto_icon59 },
  { icon: Crypto_icon60 },
  { icon: Crypto_icon61 },
  { icon: Crypto_icon62 },
  { icon: Crypto_icon63 },
  { icon: Crypto_icon64 },
  { icon: Crypto_icon65 },
  { icon: Crypto_icon66 },
  { icon: Crypto_icon67 },
  { icon: Crypto_icon68 },
  { icon: Crypto_icon69 },
  { icon: Crypto_icon70 },
  { icon: Crypto_icon71 },
  { icon: Crypto_icon72 },
  { icon: Crypto_icon73 },
  { icon: Crypto_icon74 },
  { icon: Crypto_icon75 },
  { icon: Crypto_icon76 },
  { icon: Crypto_icon77 },
  { icon: Crypto_icon78 },
  { icon: Crypto_icon79 },
  { icon: Crypto_icon80 },
  { icon: Crypto_icon81 },
  { icon: Crypto_icon82 },
  { icon: Crypto_icon83 },
  { icon: Crypto_icon84 },
  { icon: Crypto_icon85 },
  { icon: Crypto_icon86 },
  { icon: Crypto_icon87 },
  { icon: Crypto_icon88 },
  { icon: Crypto_icon89 },
  { icon: Crypto_icon90 },
  { icon: Crypto_icon91 },
  { icon: Crypto_icon92 },
  { icon: Crypto_icon93 },
  { icon: Crypto_icon94 },
  { icon: Crypto_icon95 },
  { icon: Crypto_icon96 },
  { icon: Crypto_icon97 },
  { icon: Crypto_icon98 },
  { icon: Crypto_icon99 },
  { icon: Crypto_icon100 },
  { icon: Crypto_icon101 },
  { icon: Crypto_icon102 },
  { icon: Crypto_icon103 },
  { icon: Crypto_icon104 },
  { icon: Crypto_icon105 },
  { icon: Crypto_icon106 },
  { icon: Crypto_icon107 },
  { icon: Crypto_icon108 },
  { icon: Crypto_icon109 },
  { icon: Crypto_icon110 },
  { icon: Crypto_icon111 },
  { icon: Crypto_icon112 },
  { icon: Crypto_icon113 },
  { icon: Crypto_icon114 },
  { icon: Crypto_icon115 },
  { icon: Crypto_icon116 },
  { icon: Crypto_icon117 },
  { icon: Crypto_icon118 },
  { icon: Crypto_icon119 },
  { icon: Crypto_icon120 },
  { icon: Crypto_icon121 },
  { icon: Crypto_icon122 },
  { icon: Crypto_icon123 },
  { icon: Crypto_icon124 },
  { icon: Crypto_icon125 },
  { icon: Crypto_icon126 },
  { icon: Crypto_icon127 },
  { icon: Crypto_icon128 },
  { icon: Crypto_icon129 },
  { icon: Crypto_icon130 },
  { icon: Crypto_icon131 },
  { icon: Crypto_icon132 },
  { icon: Crypto_icon133 },
  { icon: Crypto_icon134 },
  { icon: Crypto_icon135 },
  { icon: Crypto_icon136 },
  { icon: Crypto_icon137 },
  { icon: Crypto_icon138 },
  { icon: Crypto_icon139 },
  { icon: Crypto_icon140 },
  { icon: Crypto_icon141 },
  { icon: Crypto_icon142 },
  { icon: Crypto_icon143 },
  { icon: Crypto_icon144 },
  { icon: Crypto_icon145 },
  { icon: Crypto_icon146 },
  { icon: Crypto_icon147 },
  { icon: Crypto_icon148 },
  { icon: Crypto_icon149 },
  { icon: Crypto_icon150 },
  { icon: Crypto_icon151 },
  { icon: Crypto_icon152 },
  { icon: Crypto_icon153 },
  { icon: Crypto_icon154 },
  { icon: Crypto_icon155 },
  { icon: Crypto_icon156 },
  { icon: Crypto_icon157 },
  { icon: Crypto_icon158 },
  { icon: Crypto_icon159 },
  { icon: Crypto_icon160 },
  { icon: Crypto_icon161 },
  { icon: Crypto_icon162 },
  { icon: Crypto_icon163 },
  { icon: Crypto_icon164 },
  { icon: Crypto_icon165 },
  { icon: Crypto_icon166 },
  { icon: Crypto_icon167 },
  { icon: Crypto_icon168 },
  { icon: Crypto_icon169 },
  { icon: Crypto_icon170 },
  { icon: Crypto_icon171 },
  { icon: Crypto_icon172 },
  { icon: Crypto_icon173 },
  { icon: Crypto_icon174 },
  { icon: Crypto_icon175 },
  { icon: Crypto_icon176 },
  { icon: Crypto_icon177 },
  { icon: Crypto_icon178 },
  { icon: Crypto_icon179 },
  { icon: Crypto_icon180 },
  { icon: Crypto_icon181 },
  { icon: Crypto_icon182 },
  { icon: Crypto_icon183 },
  { icon: Crypto_icon184 },
  { icon: Crypto_icon185 },
  { icon: Crypto_icon186 },
  { icon: Crypto_icon187 },
  { icon: Crypto_icon188 },
  { icon: Crypto_icon189 },
  { icon: Crypto_icon190 },
  { icon: Crypto_icon191 },
  { icon: Crypto_icon192 },
  { icon: Crypto_icon193 },
  { icon: Crypto_icon194 },
  { icon: Crypto_icon195 },
  { icon: Crypto_icon196 },
  { icon: Crypto_icon197 },
  { icon: Crypto_icon198 },
  { icon: Crypto_icon199 },
  { icon: Crypto_icon200 },
  { icon: Crypto_icon201 },
  { icon: Crypto_icon202 },
  { icon: Crypto_icon203 },
  { icon: Crypto_icon204 },
  { icon: Crypto_icon205 },
  { icon: Crypto_icon206 },
  { icon: Crypto_icon207 },
  { icon: Crypto_icon208 },
  { icon: Crypto_icon209 },
  { icon: Crypto_icon210 },
  { icon: Crypto_icon211 },
  { icon: Crypto_icon212 },
  { icon: Crypto_icon213 },
  { icon: Crypto_icon214 },
  { icon: Crypto_icon215 },
];