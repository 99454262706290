import { Description } from '@mui/icons-material';
import React from 'react';
import styles from './stealthMode.module.scss';
import { ReactComponent as Inbox } from '../../assets/svg/inbox_icon.svg';
import RandomIconSpread from '../../_shared/components/RandomIconSpread/randomIconSpread.component';
import { CRYPTO_ICONS } from '../../_shared/data/cryptoIcons';
import { Link } from 'react-router-dom';

function StealthMode() {
	const svgIcons = CRYPTO_ICONS.map((cryptoIcon, index) => (
		<cryptoIcon.icon key={`icon-${index}`} />
	));

	return (
		<div className={styles.container}>
			<RandomIconSpread svgIcons={svgIcons} />
			<div className={styles.contentWrapper}>
				<p className={styles.title}>
					Harness GPU efficiency at <span>CPU</span> Scale
				</p>
				<p className={styles.description}>
					We work at the intersection of mathematics and large-scale
					distributed systems algorithms to bring Foundational AI
					Models alive on CPUs: Inference, RAG, Fine-Tuning and
					Training.
				</p>
				<button
					onClick={(e) => {
						window.location.href = 'mailto:contact@ziroh.com';
					}}
				>
					<Inbox />
					<p>contact@ziroh.com</p>
				</button>
			</div>
		</div>
	);
}

export default StealthMode;
